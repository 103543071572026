<!-- components/HamburgerMenu.vue -->
<template>
  <div class="news-memu-container theme-3">
    <div class="news-menu-row" :style="{ background: themeConfig.themeBaseColor }">
      <div class="news-menu-name-row" @click="goto('home')">
        <div v-if="domainConfig?.icon" class="menu-icon-flex">
          <img v-lazy="domainConfig.icon" alt="">
        </div>
        <div class="site-name">
          {{ domainConfig.title }}
        </div>
      </div>

      <div class="news-menu-feature-row">
        <div class="search" @click="toggleState('isSearchOpen')">
          <IconComponent :rectAttributes="isSearchOpen ? clickedSearchRect : defaultSearchRect"
            :pathD="isSearchOpen ? clickedSearchPathD : defaultSearchPathD"
            :pathFill="isSearchOpen ? 'black' : 'white'"
            :pathStroke="isSearchOpen ? 'black' : 'white'" />
        </div>

        <div class="hamburger-flex" @click="toggleState('isOpen')">
          <IconComponent :rectAttributes="isOpen ? clickedHamburgerRect : defaultHamburgerRect"
            :pathD="isOpen ? clickedHamburgerPathD1 : defaultHamburgerPathD"
            :pathFill="isOpen ? 'white' : 'black'"
            :extraPathD="isOpen ? clickedHamburgerPathD2 : ''"
            :extraPathFill="isOpen ? 'black' : ''" />
        </div>
      </div>
    </div>

    <div class="menu" :class="{ 'menu-open': isOpen }"
      :style="{ background: themeConfig.mainColorType }">
      <ul>
        <li @click="goto('home')">
          <a>Home</a>
        </li>
        <li v-for="(data,i) in $store.state.sideBar" :key="`sidebar_${i}`"
          @click="gotoItem(data.domain)">
          <a>{{ data.lable }}</a>
          <div class="arrow" />
        </li>
        <li @click="goto('privacy')">
          <a>Privacy Policy</a>
        </li>
        <li @click="goto('terms')">
          <a>Terms of Use</a>
        </li>
      </ul>
    </div>

    <div class="search-popshow" :class="{ 'menu-open': isSearchOpen }">
      <div class="search-container">
        <input v-model="query" type="text" placeholder="Search..." @keyup.enter="search">
        <button class="search-button" @click="search" />
      </div>
    </div>
  </div>
</template>

<script>
import '@/css/news/menu-3.scss';
import IconComponent from '@/pages/components/IconComponent.vue';

export default {
  components: {
    IconComponent,
  },
  props: {
    themeConfig: {
      required: true,
      type: Object,
    },
    domainConfig: {
      required: true,
      type: Object,
    },
    host: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      isOpen: false,
      isSearchOpen: false,
      query: '',
    };
  },
  computed: {
    defaultSearchRect () {
      return {
        x: 1.5,
        y: 1.5,
        width: 47,
        height: 47,
        rx: 8.5,
        stroke: 'white',
        strokeWidth: 3,
        fill: 'none',
      };
    },
    clickedSearchRect () {
      return {
        x: 0,
        y: 0,
        width: 50,
        height: 50,
        rx: 10,
        stroke: 'none',
        strokeWidth: 0,
        fill: this.themeConfig.mainColorType,
      };
    },
    defaultSearchPathD () {
      return 'M32.165 31.4275C32.2275 31.4712 32.2888 31.52 32.3438 31.5775L39.4238 38.6562C39.9 39.1325 39.9075 39.9312 39.42 40.4187C39.3048 40.5353 39.1676 40.6279 39.0164 40.6913C38.8653 40.7546 38.7031 40.7875 38.5392 40.7879C38.3753 40.7884 38.2129 40.7565 38.0614 40.694C37.9098 40.6315 37.7722 40.5396 37.6563 40.4237L30.5763 33.3437C30.5215 33.289 30.4721 33.2291 30.4288 33.165C28.1954 35.0023 25.392 36.0047 22.5 36C15.5963 36 10 30.4037 10 23.5C10 16.5962 15.5963 11 22.5 11C29.4038 11 35 16.5962 35 23.5C35 26.5087 33.9375 29.27 32.165 31.4275ZM22.5 33.5C25.1522 33.5 27.6957 32.4464 29.5711 30.5711C31.4464 28.6957 32.5 26.1522 32.5 23.5C32.5 20.8478 31.4464 18.3043 29.5711 16.4289C27.6957 14.5536 25.1522 13.5 22.5 13.5C19.8478 13.5 17.3043 14.5536 15.4289 16.4289C13.5536 18.3043 12.5 20.8478 12.5 23.5C12.5 26.1522 13.5536 28.6957 15.4289 30.5711C17.3043 32.4464 19.8478 33.5 22.5 33.5Z';
    },
    clickedSearchPathD () {
      return 'M32.165 31.4275C32.2275 31.4712 32.2888 31.52 32.3438 31.5775L39.4238 38.6562C39.9 39.1325 39.9075 39.9312 39.42 40.4187C39.3048 40.5353 39.1676 40.6279 39.0164 40.6913C38.8653 40.7546 38.7031 40.7875 38.5392 40.7879C38.3753 40.7884 38.2129 40.7565 38.0614 40.694C37.9098 40.6315 37.7722 40.5396 37.6563 40.4237L30.5763 33.3437C30.5215 33.289 30.4721 33.2291 30.4288 33.165C28.1954 35.0023 25.392 36.0047 22.5 36C15.5963 36 10 30.4037 10 23.5C10 16.5962 15.5963 11 22.5 11C29.4038 11 35 16.5962 35 23.5C35 26.5087 33.9375 29.27 32.165 31.4275ZM22.5 33.5C25.1522 33.5 27.6957 32.4464 29.5711 30.5711C31.4464 28.6957 32.5 26.1522 32.5 23.5C32.5 20.8478 31.4464 18.3043 29.5711 16.4289C27.6957 14.5536 25.1522 13.5 22.5 13.5C19.8478 13.5 17.3043 14.5536 15.4289 16.4289C13.5536 18.3043 12.5 20.8478 12.5 23.5C12.5 26.1522 13.5536 28.6957 15.4289 30.5711C17.3043 32.4464 19.8478 33.5 22.5 33.5Z';
    },
    defaultHamburgerRect () {
      return {
        width: 50,
        height: 50,
        rx: 10,
        stroke: 'none',
        strokeWidth: 0,
        fill: this.themeConfig.mainColorType,
      };
    },
    clickedHamburgerRect () {
      return {
        width: 50,
        height: 50,
        rx: 10,
        stroke: 'none',
        strokeWidth: 0,
        fill: 'white',
      };
    },
    defaultHamburgerPathD () {
      return 'M12.0704 15.6667C12.0704 14.7462 12.8166 14 13.7371 14H35.9439C36.8644 14 37.6106 14.7462 37.6106 15.6667C37.6106 16.5871 36.8644 17.3333 35.9439 17.3333H13.7371C12.8166 17.3333 12.0704 16.5871 12.0704 15.6667ZM12 25.3154C12 24.3949 12.7462 23.6487 13.6667 23.6487H36.3336C37.2541 23.6487 38.0003 24.3949 38.0003 25.3154C38.0003 26.2359 37.2541 26.982 36.3336 26.982H13.6667C12.7462 26.982 12 26.2359 12 25.3154ZM13.7371 33.1528C12.8166 33.1528 12.0704 33.899 12.0704 34.8195C12.0704 35.7399 12.8166 36.4861 13.7371 36.4861H35.9439C36.8644 36.4861 37.6106 35.7399 37.6106 34.8195C37.6106 33.899 36.8644 33.1528 35.9439 33.1528H13.7371Z';
    },
    clickedHamburgerPathD1 () {
      return 'M12.0704 15.6667C12.0704 14.7462 12.8166 14 13.7371 14H35.9439C36.8644 14 37.6106 14.7462 37.6106 15.6667C37.6106 16.5871 36.8644 17.3333 35.9439 17.3333H13.7371C12.8166 17.3333 12.0704 16.5871 12.0704 15.6667ZM12 25.3154C12 24.3949 12.7462 23.6487 13.6667 23.6487H36.3336C37.2541 23.6487 38.0003 24.3949 38.0003 25.3154C38.0003 26.2359 37.2541 26.982 36.3336 26.982H13.6667C12.7462 26.982 12 26.2359 12 25.3154ZM13.7371 33.1528C12.8166 33.1528 12.0704 33.899 12.0704 34.8195C12.0704 35.7399 12.8166 36.4861 13.7371 36.4861H35.9439C36.8644 36.4861 37.6106 35.7399 37.6106 34.8195C37.6106 33.899 36.8644 33.1528 35.9439 33.1528H13.7371Z';
    },
    clickedHamburgerPathD2 () {
      return 'M36.5227 13.4773C35.8863 12.8409 34.8546 12.8409 34.2182 13.4773L25 22.6954L15.7819 13.4773C15.1455 12.8409 14.1138 12.8409 13.4774 13.4773C12.841 14.1137 12.841 15.1454 13.4774 15.7818L22.6955 25L13.4773 34.2182C12.8409 34.8546 12.8409 35.8863 13.4773 36.5227C14.1137 37.1591 15.1454 37.1591 15.7818 36.5227L25 27.3045L34.2183 36.5227C34.8546 37.1591 35.8864 37.1591 36.5228 36.5227C37.1592 35.8863 37.1592 34.8546 36.5228 34.2182L27.3046 25L36.5227 15.7818C37.1591 15.1454 37.1591 14.1137 36.5227 13.4773Z';
    },
  },
  methods: {
    gotoItem (domain) {
      this.isOpen = false;
      setTimeout(() => {
        this.$emit('selectMenuItem', domain);
      }, 300);
    },
    search () {
      if (this.query.trim() !== '') {
        console.log(this.query);
        this.isSearchOpen = false
        // this.$emit('keyWordSearch', this.query);
        // if(this.$route)
        console.log(this.$route.name);
        this.$router.push({ name: 'search', query: { keyword: this.query } });
        if (this.$route.name == 'search') {
          this.$emit('keyWordSearch', this.query);
        }
      }
    },
    toggleState (state) {
      if (state === 'isSearchOpen') {
        this.isSearchOpen = !this.isSearchOpen;
        if (this.isSearchOpen) {
          this.isOpen = false;
        }
      } else if (state === 'isOpen') {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
          this.isSearchOpen = false;
        }
      }
    },
    goto (where) {
      console.log(this.$route.path);
      this.isOpen = false;
      if (where == 'home') {
        this.$emit('setRequestDomainHome');
      }
      if (where == 'home' && this.$route.path == '/') {
        this.scrollToTop()
        return
      }
      this.$router.push({ name: where });
    },
    scrollToTop () {
      let topHeight =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      let speed = topHeight / 10 > 100 ? topHeight / 10 : 100;
      scrollBy(0, -speed);
      // 模拟鼠标向上滚动事件
      this.scrolldelay = setTimeout(this.scrollToTop, 50);
      // 清除滚动事件，避免无法向下移动
      if (topHeight === 0) {
        clearTimeout(this.scrolldelay);
        this.scrolldelay = null;
      }
    },
  },
};
</script>
